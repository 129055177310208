import React, { useState, useRef, useEffect } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import styled from 'styled-components';
import Layout from '@lekoarts/gatsby-theme-minimal-blog/src/components/layout';
import Recaptcha from 'react-recaptcha';
import 'isomorphic-fetch';

const StyledInput = styled.input`
  color: #2a2a2a;
  width: 100%;
  margin: 10px 0px;
  padding: 10px;
`;
const StyledButton = styled.button`
  width: 100%;
  height: 25px;
  margin: 10px 0px;
  display: inline-block;

  border: none;
  background-image: none;
  background-color: #27496d;
  color: white;

  letter-spacing: 1px;
  transition: all 0.1s linear;

  &:hover {
    cursor: pointer;
    background: #0c7b93;
  }
`;

const Wrapper = styled.div`
  padding: 10px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

type Ev = React.ChangeEvent<HTMLInputElement>;

const EmailListForm: React.FunctionComponent<{}> = () => {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [busy, setBusy] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [validated, setValidated] = useState(false);
  const captcha = useRef(null);
  useEffect(() => {
    captcha?.current?.execute();
  }, []);
  return (
    <Layout>
      <Wrapper>
        {submitted ? (
          <h1>Thanks!</h1>
        ) : (
          <>
            <h2>
              RSS
            </h2>
            <Row>
              <p>
                Subscribe via
              </p>
              <a href="https://aleksandr.life/rss.xml" target="_blank" rel="noreferrer">
                <p style={{ marginLeft: '3px' }}>
                  RSS
                </p>
              </a>
            </Row>
            <h2>
              Or, here, you can subscribe for the infrequent newsletter from this
              blog:
            </h2>
            <div id="subscribe-form" style={{ maxWidth: '400px' }}>
              <form>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <StyledInput
                    value={email}
                    required
                    placeholder="Email address"
                    name="email"
                    type="text"
                    onChange={(event: Ev) => setEmail(event.target.value)}
                  />
                  <StyledInput
                    value={firstName}
                    placeholder="First Name (optional)"
                    name="First Name (optional)"
                    type="text"
                    onChange={(event: Ev) => setFirstName(event.target.value)}
                  />
                  <StyledInput
                    value={lastName}
                    placeholder="Last Name (optional)"
                    name="Last Name (optional)"
                    type="text"
                    onChange={(event: Ev) => setLastName(event.target.value)}
                  />
                </div>
              </form>
              {validated && (
                <StyledButton
                  disabled={busy || !email.length}
                  onClick={() => {
                    setBusy(true);
                    addToMailchimp(email, { FNAME: firstName, LNAME: lastName })
                      .then(() => {
                        setEmail('');
                        setFirstName('');
                        setLastName('');
                        setSubmitted(true);
                      })
                      .finally(() => setBusy(false));
                  }}
                >
                  Subscribe
                </StyledButton>
              )}
              {typeof window !== 'undefined' && (
                <Recaptcha
                  ref={captcha}
                  size="invisible"
                  verifyCallback={async (response) => {
                    const validityRes = await fetch('/api/captcha', {
                      method: 'POST',
                      body: JSON.stringify({ response }),
                      headers: { 'Content-Type': 'application/json' },
                    });
                    setValidated(validityRes.ok);
                  }}
                  sitekey="6LdhodkUAAAAAF9fiIQ_L5BLXE3f5gaB3ITXuBw3"
                />
              )}
            </div>
          </>
        )}
      </Wrapper>
    </Layout>
  );
};

export default EmailListForm;
